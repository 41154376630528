<template>
  <div>
    <b-card id="not-found" class="centered-card">
      <template #header>
        <GradientHeading>Page Not Found</GradientHeading>
      </template>

      <h2 class="text-center">404</h2>
      <p class="text-center">The page you are looking for does not exist.</p>
      <p v-if="$store.getters.isAuthenticated" class="text-center">
        <b-button :to="{ name: 'Home' }" class="btn">
          Return To Dashboard
        </b-button>
      </p>
      <p v-else class="text-center">
        <b-button :to="{ name: 'Login' }" class="btn"> Go to Login </b-button>
      </p>
    </b-card>
  </div>
</template>

<script>
import GradientHeading from '@/components/Elements/GradientHeading/GradientHeading.vue'

export default {
  components: { GradientHeading },
  data() {
    return {
      email: '',
      password: '',
      errors: {}
    }
  },
  computed: {
    errorList() {
      return Object.entries(this.errors).map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}: ${value[0]}`
        } else {
          return value
        }
      })
    }
  },
  methods: {
    handleSubmit() {
      this.errors = {}
      const credentials = { email: this.email, password: this.password }
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          Promise.all([
            this.$store.dispatch('getProfile'),
            this.$store.dispatch('getMerchantCount')
          ]).then(() => {
            this.$nextTick(() => {
              if (this.$store.getters.profile.organization.in_legacy_app) {
                this.$router.push({ name: 'TransactionSearch' })
              } else {
                this.$router.push(
                  this.$route.query.next || { name: 'CustomerList' }
                )
              }
            })
          })
        })
        .catch(err => {
          if (err?.response?.data) {
            console.log('error.response.data', err.response.data)
            this.errors = err.response.data
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.errors {
  margin-top: 20px;

  color: $danger;
}

.error {
  &::first-letter {
    text-transform: capitalize;
  }
}
</style>
