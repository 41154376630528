<template>
  <div id="login">
    <b-card>
      <template #header>
        <h2 class="mb-0">Merchant Login</h2>
      </template>
      <b-form class="text-center" @submit.prevent="handleSubmit">
        <b-form-group>
          <b-form-input
            v-model="email"
            placeholder="Email"
            data-cy="email"
            trim
            lazy
          />
        </b-form-group>

        <b-form-group>
          <b-form-input
            v-model="password"
            type="password"
            placeholder="Password"
            data-cy="password"
          />
        </b-form-group>

        <b-button pill variant="outline-blue" type="submit" data-cy="login">
          Login
        </b-button>

        <div class="errors">
          <div v-for="error in errorList" :key="error" class="error">
            {{ error }}
          </div>
        </div>

        <router-link :to="{ name: 'ForgotPassword' }">
          Trouble logging in?
        </router-link>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import store from '@/store/index'
export default {
  beforeRouteEnter: async (to, from, next) => {
    if ((await store.getters.isAuthenticated) && !to.meta.isSSO) {
      next({
        name: 'Home'
      })
    } else {
      next()
    }
  },
  data() {
    return {
      email: '',
      password: '',
      errors: {}
    }
  },

  computed: {
    errorList() {
      return Object.entries(this.errors).map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}: ${value[0]}`
        } else {
          return value
        }
      })
    },
    isSSO() {
      return this.$route.query.id && this.$route.query.token
    }
  },
  created() {
    if (this.isSSO) {
      if (this.$route.query.account_id) {
        this.$store.commit('setCurrentAccount', {
          id: this.$route.query.account_id
        })
      }
      this.handleSubmit()
    }
  },
  methods: {
    async handleSubmit() {
      this.errors = {}
      const credentials = this.isSSO
        ? { id: this.$route.query.id, token: this.$route.query.token }
        : { email: this.email, password: this.password }

      try {
        await this.$store.dispatch('login', credentials)
        await this.$store.dispatch('getProfile')

        if (this.$store.getters.accessClaims.roles.length === 0) {
          this.$router.push({ name: 'UnAuthorized' })
        } else {
          await Promise.all([
            this.$store.dispatch('getMerchantCount'),
            this.$store.dispatch('getMerchant', { merchantID: null })
          ])

          this.$nextTick(() => {
            if (this.$store.getters.profile.organization.in_legacy_app) {
              this.$router.push({ name: 'TransactionSearch' })
            } else {
              this.$router.push(
                this.$route.query.next || {
                  name: 'Home'
                }
              )
            }
          })
        }
      } catch (error) {
        const data = error?.response?.data

        if (data) {
          this.errors = data
        } else {
          console.error(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#login {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 500px;

  transform: translate(-50%, -50%);
}

.errors {
  margin: 5px 0 10px;

  color: $danger;
}

.error {
  &::first-letter {
    text-transform: capitalize;
  }
}

.login-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin-bottom: 5px;
  }

  button {
    width: fit-content;
    margin-bottom: 5px;
  }
}
</style>
