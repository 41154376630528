<template>
  <ValidationProvider
    v-slot="{ errors }"
    v-bind="$attrs"
    :ref="name"
    :name="name"
    :mode="validationMode"
    :rules="
      type !== 'phone'
        ? rules
        : {
            required: !!rules && rules.includes('required'),
            phone: { isValid: isPhoneValid }
          }
    "
    :vid="name"
  >
    <b-form-group
      class="h-form-group"
      :label="$attrs.label"
      :label-for="kebabName"
    >
      <b-input-group class="v-flex">
        <TelephoneInput
          v-if="type === 'phone'"
          :id="kebabName"
          v-bind="$attrs"
          :input-options="{
            id: kebabName + '-input',
            placeholder: 'Enter a phone number',
            maxlength: 14
          }"
          :name="name"
          :disabled="!editing"
          default-country="US"
          :auto-default-country="false"
          :only-countries="['US', 'CA']"
          mode="national"
          :data-cy="makeSelector($attrs)"
          :valid-characters-only="true"
          v-on="$listeners"
          @validate="onPhoneValid"
        />

        <currency-input
          v-else-if="type === 'amount'"
          :id="kebabName"
          v-bind="$attrs"
          class="amount-input"
          :name="name"
          :disabled="!editing"
          :data-cy="makeSelector($attrs)"
          placeholder="$"
          v-on="$listeners"
        />

        <money
          v-else-if="type === 'amount2'"
          :id="kebabName"
          v-bind="{ ...$attrs, precision: precision, prefix: '$' }"
          class="amount-input"
          :name="name"
          :disabled="!editing"
          :data-cy="makeSelector($attrs)"
          :masked="false"
          v-on="$listeners"
        />

        <money
          v-else-if="type === 'percent'"
          :id="kebabName"
          v-bind="{ ...$attrs, precision: precision, suffix: '%' }"
          class="percent-input"
          :name="name"
          :disabled="!editing"
          :data-cy="makeSelector($attrs)"
          v-on="$listeners"
        />

        <DatePicker
          v-else-if="type === 'date'"
          v-slot="{ inputValue, inputEvents }"
          v-bind="$attrs"
          :update-on-input="false"
          :masks="{ input: ['MM/DD/YYYY'], data: ['YYYY-MM-DD'] }"
          :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
          v-on="$listeners"
        >
          <input
            :id="kebabName"
            class="vue-date-picker"
            :value="inputValue"
            :disabled="!editing"
            placeholder="MM/DD/YYYY"
            :data-cy="makeSelector($attrs)"
            :maxlength="10"
            type="text"
            autocomplete="off"
            v-on="inputEvents"
          />
        </DatePicker>

        <b-form-input
          v-else
          :id="kebabName"
          v-bind="$attrs"
          :disabled="!editing"
          :name="name"
          :data-cy="makeSelector($attrs)"
          v-on="$listeners"
        />
        <div class="text-danger field-errors">
          <span v-for="err in errors" :key="err">
            {{ err }}
          </span>
        </div>
      </b-input-group>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
const DatePicker = () => import('v-calendar/lib/components/date-picker.umd')
const TelephoneInput = () =>
  import('@/components/FormInputs/TelephoneInput.vue')
import { Money } from 'v-money'
import moment from 'moment'
import { kebabCase } from 'lodash-es'

export default {
  components: {
    DatePicker,
    TelephoneInput,
    Money
  },

  props: {
    append: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    precision: {
      type: Number,
      default: 2
    },
    prepend: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    wholePercent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      countryCode: '',
      isPhoneValid: false
    }
  },

  computed: {
    kebabName() {
      return kebabCase(this.name)
    },

    validationMode() {
      if (this.mode) return this.mode

      switch (this.type) {
        case 'percent':
        case 'date':
        case 'amount2':
          return 'aggressive'
        default:
          return 'eager'
      }
    }
  },

  watch: {
    editing(val) {
      if (val)
        setTimeout(() => {
          this.$refs[this.name].reset()
        }, 100)
    }
  },

  methods: {
    moment,

    onPhoneValid(phone) {
      this.isPhoneValid = Boolean(phone.valid)
    },

    makeSelector(attrs) {
      const attr = attrs?.cy ?? attrs?.label ?? attrs?.id ?? ''
      return kebabCase(attr)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-group-vertical > button {
  margin-bottom: 10px;
}

%control-base {
  width: 100%;
  height: 31px;

  color: $input-font-color;
  font-size: 1em;
  font-family: $body-font-family;

  border: 1px solid $input-border;
  border-radius: 5px !important;
}

.vue-tel-input {
  @extend %control-base;
}

.vue-date-picker {
  @extend %control-base;
  padding: 6px 12px;
}

.view-form .h-form-group .form-control {
  @extend %control-base;
}

.amount-input {
  @extend %control-base;
  align-self: flex-end;
  width: 30%;
  padding-left: 1em;
}

.view-form .h-form-group .form-control:disabled,
input.v-money:disabled,
.vue-date-picker:disabled,
.vue-tel-input:disabled {
  display: block;

  white-space: nowrap;
  text-overflow: ellipsis;

  background-color: transparent;
  border-style: none;
}
</style>
