import endpoints from '@/utils/endpoints'
import _Vue from 'vue'

export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void

export interface PluginObject<T> {
  install: PluginFunction<T>
  [key: string]: any
}

export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$endpoints = endpoints
  }
}
