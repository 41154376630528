const GeneralSettingsView = () =>
  import('@/views/settings/GeneralSettingsView/GeneralSettingsView.vue')

const MerchantUserListView = () =>
  import(
    '@/views/settings/MerchantUsers/MerchantUserListView/MerchantUserListView.vue'
  )

const RoleListView = () =>
  import('@/views/settings/ManageRoleView/RoleListView.vue')

const RoleDetailView = () =>
  import('@/views/settings/ManageRoleView/RoleDetailView.vue')

const RoleCreateView = () =>
  import('@/views/settings/ManageRoleView/RoleCreateView.vue')

const ManageTagsView = () =>
  import('@/views/settings/ManageTagsView/ManageTagsView.vue')

const MerchantIntegrationsView = () =>
  import(
    '@/views/settings/MerchantIntegrationsView/MerchantIntegrationsView.vue'
  )

const MerchantPasstimeView = () =>
  import('@/views/settings/MerchantPasstimeView/MerchantPasstimeView.vue')

const MerchantIturanView = () =>
  import('@/views/settings/MerchantIturanView/MerchantIturanView.vue')

export default [
  {
    path: '/',
    component: GeneralSettingsView,
    name: 'GeneralSettings'
  },
  {
    path: 'users',
    component: MerchantUserListView,
    name: 'MerchantUserList',
    meta: { require_roles: ['super_admin', 'admin', 'is_user_update_allowed'] }
  },
  {
    path: 'roles/create',
    component: RoleCreateView,
    name: 'RoleCreateView',
    meta: { require_roles: ['super_admin', 'admin'] }
  },
  {
    path: 'roles/',
    component: RoleListView,
    name: 'MerchantRoleList',
    meta: { require_roles: ['super_admin', 'admin'] }
  },
  {
    path: 'roles/:roleID',
    component: RoleDetailView,
    name: 'RoleDetailView',
    meta: { require_roles: ['super_admin', 'admin'] }
  },
  {
    path: 'tags',
    component: ManageTagsView,
    name: 'ManageTags',
    meta: { require_roles: ['super_admin', 'admin'] }
  },
  {
    path: 'integrations',
    component: MerchantIntegrationsView,
    name: 'MerchantIntegration',
    meta: { require_roles: ['super_admin'] }
  },
  {
    path: 'passtime',
    component: MerchantPasstimeView,
    name: 'MerchantPasstime',
    meta: {
      require_roles: ['admin', 'super_admin', 'is_merchant_passtime_allowed']
    }
  },
  {
    path: 'ituran',
    component: MerchantIturanView,
    name: 'MerchantIturan',
    meta: {
      require_roles: ['admin', 'super_admin', 'is_merchant_ituran_allowed']
    }
  }
]
