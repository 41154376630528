import { DateTimeFormats } from 'vue-i18n'

export const messages = {
  en: {
    labels: {
      MARKETING: 'Marketing',
      PAYMENT: 'Collections & Payments',
      SUPPORT: 'Customer Service',
      TEXT: 'Text',
      EMAIL: 'Email'
    },
    errors: {
      ssn_invalid_format: 'SSN must be 9 digits, no dashes (-)',
      autopay: {
        already_configured: 'Autopay is already setup by another customer'
      },
      payment_method: {
        not_found: 'Invalid payment method specified for autopay'
      },
      customer: {
        shared_secret_incorrect:
          'Provided information does not match customer record',
        dob_in_future: 'Date of Birth Can Not Be in The Future',
        unsubscribed: 'The customer has opted out of email or text messages'
      },
      pin: {
        mismatch: 'Pins do not match'
      },
      user: {
        not_found: 'No user found with matching credentials',
        email_in_use: 'Email address already in use',
        org_already_set: 'User already belongs to an organization'
      },
      password: {
        already_set: 'Password has already been set',
        mismatch: 'Passwords do not match'
      },
      token: {
        invalid: 'Invalid Token'
      },
      location: {
        customer_account_mismatch:
          'Customer does not belong to provided merchant location'
      },
      template: {
        schedule_clash: 'A template already exists for the chosen days'
      },
      card_number: {
        too_long: 'card_number can be at most 16 characters',
        too_short: 'card_number must be at least 15 characters'
      },
      account: {
        already_paid: 'This account has already been paid',
        current_invoice_required: 'Account requires a current invoice',
        external_id: {
          clash: 'Account with provided external ID already exists'
        },
        negative_convenience_fee: 'Convenience Fee Can Not be a Negative Value',
        amount_due_required: 'Amount Due is Required',
        inactive: 'Account is set to Inactive',
        do_not_contact: 'Account status prevents contact',
        due_date: {
          autopay_cap: 'Due date with Autopay must not exceed the 28th'
        }
      },
      payment: {
        minimum: 'Payment must be larger than $1.00'
      },
      payment_amount: {
        too_small: 'Requested payment amount is less than the minimum allowed',
        too_large:
          'Requested payment amount is greater than the maximum allowed'
      },
      phone_number: {
        duplicate: 'Phone Number is Already in Use'
      },
      card_type: {
        invalid: 'Invalid card type'
      },
      owner: {
        incorrect_percentage: 'Ownership Percentage is Invalid'
      },
      integrations: {
        unresolvable_action: 'This action must be manually resolved'
      }
    }
  }
}

export const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  }
}

export const dateTimeFormats: DateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
  }
}
