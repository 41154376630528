var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',_vm._b({ref:_vm.name,attrs:{"name":_vm.name,"mode":_vm.validationMode,"rules":_vm.type !== 'phone'
      ? _vm.rules
      : {
          required: !!_vm.rules && _vm.rules.includes('required'),
          phone: { isValid: _vm.isPhoneValid }
        },"vid":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{staticClass:"h-form-group",attrs:{"label":_vm.$attrs.label,"label-for":_vm.kebabName}},[_c('b-input-group',{staticClass:"v-flex"},[(_vm.type === 'phone')?_c('TelephoneInput',_vm._g(_vm._b({attrs:{"id":_vm.kebabName,"input-options":{
          id: _vm.kebabName + '-input',
          placeholder: 'Enter a phone number',
          maxlength: 14
        },"name":_vm.name,"disabled":!_vm.editing,"default-country":"US","auto-default-country":false,"only-countries":['US', 'CA'],"mode":"national","data-cy":_vm.makeSelector(_vm.$attrs),"valid-characters-only":true},on:{"validate":_vm.onPhoneValid}},'TelephoneInput',_vm.$attrs,false),_vm.$listeners)):(_vm.type === 'amount')?_c('currency-input',_vm._g(_vm._b({staticClass:"amount-input",attrs:{"id":_vm.kebabName,"name":_vm.name,"disabled":!_vm.editing,"data-cy":_vm.makeSelector(_vm.$attrs),"placeholder":"$"}},'currency-input',_vm.$attrs,false),_vm.$listeners)):(_vm.type === 'amount2')?_c('money',_vm._g(_vm._b({staticClass:"amount-input",attrs:{"id":_vm.kebabName,"name":_vm.name,"disabled":!_vm.editing,"data-cy":_vm.makeSelector(_vm.$attrs),"masked":false}},'money',Object.assign({}, _vm.$attrs, {precision: _vm.precision, prefix: '$'}),false),_vm.$listeners)):(_vm.type === 'percent')?_c('money',_vm._g(_vm._b({staticClass:"percent-input",attrs:{"id":_vm.kebabName,"name":_vm.name,"disabled":!_vm.editing,"data-cy":_vm.makeSelector(_vm.$attrs)}},'money',Object.assign({}, _vm.$attrs, {precision: _vm.precision, suffix: '%'}),false),_vm.$listeners)):(_vm.type === 'date')?_c('DatePicker',_vm._g(_vm._b({attrs:{"update-on-input":false,"masks":{ input: ['MM/DD/YYYY'], data: ['YYYY-MM-DD'] },"model-config":{ type: 'string', mask: 'YYYY-MM-DD' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"vue-date-picker",attrs:{"id":_vm.kebabName,"disabled":!_vm.editing,"placeholder":"MM/DD/YYYY","data-cy":_vm.makeSelector(_vm.$attrs),"maxlength":10,"type":"text","autocomplete":"off"},domProps:{"value":inputValue}},inputEvents))]}}],null,true)},'DatePicker',_vm.$attrs,false),_vm.$listeners)):_c('b-form-input',_vm._g(_vm._b({attrs:{"id":_vm.kebabName,"disabled":!_vm.editing,"name":_vm.name,"data-cy":_vm.makeSelector(_vm.$attrs)}},'b-form-input',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticClass:"text-danger field-errors"},_vm._l((errors),function(err){return _c('span',{key:err},[_vm._v(" "+_vm._s(err)+" ")])}),0)],1)],1)]}}])},'ValidationProvider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }