import moment from 'moment'

export default function () {
  return {
    accessToken: null,
    refreshToken: null,
    lastActivity: moment().utc().unix(),
    isInactive: false,
    profile: null
  }
}
