import {
  alpha_num,
  confirmed,
  digits,
  email,
  max,
  max_value,
  min_value,
  numeric,
  required
} from 'vee-validate/dist/rules'

import moment from 'moment'
import { extend } from 'vee-validate'

export function difference(value, { diff, against }) {
  return parseInt(value) != 0 && parseInt(against) != 0
    ? against - value >= diff
    : true
}

export function equalTo(value, { length }) {
  return value.length == parseInt(length)
}

export function greaterThan(value, { length }) {
  return value.length >= parseInt(length)
}

export function lessThan(value, { length }) {
  return value.length <= parseInt(length)
}

export function maxPercentage(value, { max_percentage }) {
  const val = value.toString().replace('%', '').replaceAll(',', '')
  return parseInt(val) <= parseInt(max_percentage) * parseInt(val)
}

export function minPercentage(value, { min_percentage }) {
  const val = value.toString().replace('%', '').replaceAll(',', '')
  return value ? parseInt(val) >= parseInt(min_percentage) : true
}

export function presentOrFuture(value) {
  const today = moment().format('YYYY/MM/DD')
  const diff = moment(value, 'YYYY/MM/DD').diff(
    moment(today, 'YYYY/MM/DD'),
    'days'
  )
  return diff >= 0
}

export function validNumber(value) {
  return value.length >= 1 ? value >= 0 : true
}

extend('after', {
  params: ['after'],
  validate: (value: string, { after }: Record<string, any>) =>
    moment(value).isAfter(after),

  message: 'Date must be after {after}'
})

extend('alpha_num', alpha_num)

extend('before', {
  params: ['before'],
  validate: (value: string, { before }: Record<string, any>) =>
    moment(value).isBefore(before),

  message: 'Date must be before {before}'
})

extend('confirmed', confirmed)

extend('date', {
  validate(value) {
    const date = moment(value, 'YYYY-MM-DD', true)
    return date.isValid()
  },
  message: 'Date is not valid'
})

extend('diff', {
  validate(value, { diff, against }: Record<string, any>) {
    return difference(value, { diff, against })
  },
  params: ['diff', 'against'],
  message: 'Must be at least ${diff} difference'
})

extend('digits', digits)

extend('email', { ...email, message: 'Email is not valid' })

extend('equalTo', {
  validate(value, { length }) {
    return equalTo(value, { length })
  },
  params: ['length'],
  message: 'Must be equal to {length} characters'
})

extend('greaterThan', {
  validate(value, { length }) {
    return greaterThan(value, { length })
  },
  params: ['length'],
  message: 'Must be longer than {length} characters'
})

extend('lessThan', {
  validate(value, { length }) {
    return lessThan(value, { length })
  },
  params: ['length'],
  message: 'Must be less than {length} characters.'
})

extend('maxPercentage', {
  validate(value, { max_percentage }: Record<string, any>) {
    return maxPercentage(value, { max_percentage })
  },
  params: ['max_percentage'],
  message: 'Must be at most {max_percentage}%'
})

extend('minPercentage', {
  validate(value, { min_percentage }: Record<string, any>) {
    return minPercentage(value, { min_percentage })
  },
  params: ['min_percentage'],
  message: 'Must be at least {min_percentage}%'
})

extend('min_amount', {
  ...min_value,
  message: 'Must be at least ${min}.00'
})

extend('max_amount', {
  ...max_value,
  message: 'Must be at most ${max}.00'
})

extend('numeric', numeric)
extend('min_value', {
  ...min_value,
  message: 'Must be greater than {min}'
})
extend('max_value', {
  ...max_value,
  message: 'Must be lower than {max}'
})

extend('passwordConfirmed', {
  ...confirmed,
  message: 'Passwords do not match'
})

extend('passwordRequirements', {
  validate(value: string) {
    const passwordLength = 8 <= value.length
    const hasUpperCase = /[A-Z]/.test(value)
    const hasLowerCase = /[a-z]/.test(value)
    const hasNumbers = /\d/.test(value)
    const hasSpecialCharacters = /\W/.test(value)
    return (
      passwordLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialCharacters
    )
  },
  message:
    'Must be at least 8 characters long and contain each of the following: one lowercase, one uppercase, one number, one special character'
})

extend('phone', {
  params: ['isValid'],
  validate: (_, { isValid }: Record<string, any>) => isValid,
  message: 'Phone number is not valid'
})

// Just a sanity check: https://stackoverflow.com/a/19844362
// * Every postal code system uses only A-Z and/or 0-9 and sometimes space/dash
// The shortest postal code format is Sierra Leone with NN
// The longest is American Samoa with NNNNN-NNNNNN
// Allow one space or dash.
// Should not begin or end with space or dash
extend('postal_code', {
  validate: (value: string) =>
    /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i.test(value),
  message: 'Invalid postal code'
})

extend('presentOrFuture', {
  validate(value) {
    return presentOrFuture(value)
  },
  message: "Must be today's date or later"
})

extend('promiseTime', {
  validate(value: Date) {
    const future = moment().add(30, 'minutes')
    return future <= moment(value)
  },
  message: 'Promise time must be at least 30 minutes from now'
})

extend('required', { ...required, message: 'Required' })

extend('URL', {
  validate(value: string) {
    try {
      new URL(value)
      return true
    } catch {
      return false
    }
  },
  message: 'Must be a valid URL'
})

extend('validNumber', {
  validate(value) {
    return validNumber(value)
  },
  message: 'Must be a valid number'
})

extend('minAmount', {
  validate(value: string, args: any) {
    const minAmount = parseFloat(args.min)
    const amount = parseFloat(value)
    return minAmount <= amount
  },
  params: ['min'],
  message: (_, values) => `Must be at least $${values.min}`
})

extend('maxAmount', {
  validate(value: string, args: any) {
    const maxAmount = parseFloat(args.max)
    const amount = parseFloat(value)
    return maxAmount >= amount
  },
  params: ['max'],
  message: (_, values) => `Must be at most $${values.max}`
})

extend('maxLength', {
  ...max,
  message: (_, values) => `Must be at most ${values.length} characters`
})

const decimalArgs = { decimals: '*', separator: '.' }
extend('decimal', {
  validate(value, args: any) {
    const decimals = args.decimals || decimalArgs.decimals
    const separator = args.separator || decimalArgs.separator
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    )
    return {
      valid: regex.test(value)
    }
  },
  message: 'Field must contain only decimal values'
})

extend('max_amount_volume', {
  ...max_value,
  message: 'Must be less than or equal to Monthly Volume: ${max}.00'
})
