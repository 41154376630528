<template>
  <div v-if="totalPages && totalPages > 1" class="table-footer" v-bind="$attrs">
    <div class="table-settings">
      <label for="num-per-page"> Rows Per Page</label>
      <b-form-select
        v-if="pageSizeOptions && pageSizeOptions.length"
        id="num-per-page"
        v-model="currentSize"
        size="sm"
        :options="pageSizeOptions"
        @change="$emit('pageSizeChanged', currentSize)"
      />
    </div>

    <div class="page-counter">
      {{ currentPage }} of {{ totalPages }}

      <div class="table-nav">
        <div @click="changePage(-1)">
          <BIcon icon="caret-left-fill" />
        </div>
        <div @click="changePage(1)">
          <BIcon icon="caret-right-fill" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    pageSizeOptions: {
      type: Array,
      default: () => [50, 75, 100]
    },
    currentPageSize: {
      type: Number,
      default: 0
    },
    totalPages: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentSize: this.currentPageSize
    }
  },

  methods: {
    changePage(offset) {
      const newPage = this.currentPage + offset
      if (newPage > 0 && newPage <= this.totalPages) {
        this.$emit('pageChanged', newPage)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  margin-top: auto;
  padding: 1em;

  color: gray;
  font-size: small;

  background: lighten(gray, 47%);

  #num-per-page {
    min-width: 4.5em;
  }
}

.page-counter,
.table-settings {
  display: flex;
  align-items: center;

  white-space: nowrap;

  & + & {
    margin-left: 30px;
  }
}

.table-nav {
  display: flex;
  align-items: center;

  white-space: nowrap;

  & + & {
    margin-left: 30px;
  }
}

label {
  margin: 0;
  margin-right: 20px;
}
</style>
