const MovePaymentsView = () =>
  import('@/views/DataAdmin/MovePaymentsView/MovePaymentsView')

export default [
  {
    path: '/customers/move-payment',
    component: MovePaymentsView,
    name: 'MovePaymentsView'
  }
]
