import { render, staticRenderFns } from "./MessageTemplateEmail.vue?vue&type=template&id=6e09c9d1&scoped=true"
import script from "./MessageTemplateEmail.vue?vue&type=script&lang=ts"
export * from "./MessageTemplateEmail.vue?vue&type=script&lang=ts"
import style0 from "./MessageTemplateEmail.vue?vue&type=style&index=0&id=6e09c9d1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e09c9d1",
  null
  
)

export default component.exports