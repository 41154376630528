import { render, staticRenderFns } from "./MessageTemplateListView.vue?vue&type=template&id=0288e31c&scoped=true"
import script from "./MessageTemplateListView.vue?vue&type=script&lang=ts"
export * from "./MessageTemplateListView.vue?vue&type=script&lang=ts"
import style0 from "./MessageTemplateListView.vue?vue&type=style&index=0&id=0288e31c&prod&lang=scss"
import style1 from "./MessageTemplateListView.vue?vue&type=style&index=1&id=0288e31c&prod&lang=scss"
import style2 from "./MessageTemplateListView.vue?vue&type=style&index=2&id=0288e31c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0288e31c",
  null
  
)

export default component.exports