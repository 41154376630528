export default {
  setNumMerchants(state, { numMerchants }) {
    state.numMerchants = numMerchants
  },
  setCurrentMerchant(state, { merchant }) {
    state.currentMerchant = merchant
  },
  setMerchantLoading(state, { loading }) {
    state.merchantLoading = loading
  },
  setMerchantPage(state, { merchants }) {
    state.merchants = merchants
    state.merchantLoading = false
  },
  setNumCustomers(state, { numCustomers }) {
    state.numCustomers = numCustomers
  },
  setCustomerLoading(state, loading) {
    state.customerLoading = loading
  },
  setCustomerPage(state, { customers }) {
    state.customers = customers
    state.customerLoading = false
  },
  setRecentPaymentLoading(state, loading) {
    state.recentPaymentLoading = loading
  },
  setNumAccounts(state, { numAccounts }) {
    state.numAccounts = numAccounts
  },
  setAccountLoading(state, { loading }) {
    state.accountLoading = loading
  },
  setAccountPage(state, { accounts }) {
    state.accounts = accounts
    state.accountLoading = false
  },
  setTransactionReportURL(state, { reportURL }) {
    state.transactionReportURL = reportURL
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle
  },
  setCSVData(state, data) {
    state.csvData = data
  },
  setRefreshComplete(state, isRefreshComplete) {
    state.isRefreshComplete = isRefreshComplete
  },
  setAccountPromises(state, promises) {
    state.accountPromises = promises
  },
  setAccountPromisesLoading(state, isLoading) {
    state.accountPromisesLoading = isLoading
  },
  setMerchantAccounts(state, merchantAccounts) {
    state.merchantAccounts = merchantAccounts
  },
  setMerchantAccountsLoading(state, isLoading) {
    state.merchantAccountsLoading = isLoading
  },
  clearCurrentCustomer(state) {
    state.currentCustomer = {}
  },
  setCurrentCustomer(state, customer) {
    state.currentCustomer = customer
  },
  clearCurrentAccount(state) {
    state.currentAccount = {}
  },
  setCurrentAccount(state, account) {
    state.currentAccount = account
  },
  setLocations(state, locations) {
    state.locations = locations
  },
  setCurrentLocation(state, location) {
    state.currentLocation = location
  },
  setSelectedLocation(state, location) {
    state.selectedLocation = location
  },
  setCurrentMerchantAccount(state, merchantAccount) {
    state.currentMerchantAccount = merchantAccount
  },
  setAccountTags(state, tags) {
    const withText =
      tags?.map(tag => {
        tag['text'] = tag.name
        return tag
      }) ?? []

    state.accountTags = withText
  },
  setGroup(state, group) {
    state.group = group
  },
  clearGroup(state) {
    state.group = null
  },
  setUsers(state, users) {
    state.users = users
  },
  setOauthLinks(state, oauthLinks) {
    state.oauthLinks = oauthLinks
  },
  setFailedActions(state, actions) {
    state.failedActions = actions
  },
  setReceiptModalVisibility(state, isOpen) {
    if (!isOpen) {
      // When closing the modal we should clear the invoice
      state.currentPayment = null
    }
    state.receiptModalOpen = isOpen
  },
  setCurrentPayment(state, payment) {
    state.currentPayment = payment
  },
  setBinInfo(state, binInfo) {
    state.binInfo = binInfo
  },
  setBinInfoLoading(state, isLoading) {
    state.binInfoLoading = isLoading
  },
  setRoleLoading(state, isLoading) {
    state.roleLoading = isLoading
  },
  setRole(state, role) {
    state.role = role
  },
  setDashboardDateRange(state, dateRange) {
    state.dashboardDateRange = dateRange
  }
}
