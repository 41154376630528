<template>
  <div id="app" class="v-flex">
    <ContainerWithNav>
      <router-view v-if="isInfoStored" />
    </ContainerWithNav>
    <InactiveNotice />
  </div>
</template>

<script>
import ContainerWithNav from '@/components/PageContainers/ContainerWithNav/ContainerWithNav.vue'
import InactiveNotice from '@/components/Authentication/InactiveNotice/InactiveNotice.vue'

export default {
  components: {
    ContainerWithNav,
    InactiveNotice
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },

    isInfoStored() {
      if (this.$store.getters.isAuthenticated) {
        return (
          this.$store.state.dataStore.isRefreshComplete ||
          this.$store.getters.currentMerchant != null
        )
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
html,
body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: stretch;
  overflow: auto;

  background: transparent linear-gradient(340deg, $dark-blue 0%, $blue 100%) 0%
    0% no-repeat padding-box;
}
</style>
