import { render, staticRenderFns } from "./SnippetTrigger.vue?vue&type=template&id=645f9e16&scoped=true"
import script from "./SnippetTrigger.vue?vue&type=script&lang=js"
export * from "./SnippetTrigger.vue?vue&type=script&lang=js"
import style0 from "./SnippetTrigger.vue?vue&type=style&index=0&id=645f9e16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645f9e16",
  null
  
)

export default component.exports