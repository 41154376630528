import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import moment from 'moment'

const state = {
  accessToken: null,
  refreshToken: null,
  lastActivity: moment().utc().unix(),
  isInactive: false,
  profile: null,
  heartbeat: null
}

// Attempt to get tokens from local storage
const accessToken = localStorage.getItem('blytzpay_access_token')
const refreshToken = localStorage.getItem('blytzpay_refresh_token')

if (accessToken) {
  state['accessToken'] = accessToken
  state['refreshToken'] = refreshToken
}

export default {
  state: () => state,
  mutations,
  actions,
  getters
}
