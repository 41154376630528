











































import Table from '@/components/Table/Table.vue'
import tableMixins from '@/mixins/TableMixins/tableMixins.js'
import endpoints from '@/utils/endpoints.js'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    Table
  }
})
export default class MessageTemplateList extends mixins(tableMixins) {
  currentPage = 1
  handlePageChange!: any
  handlePageSizeChange!: any
  isBusy = true
  messageTemplates = []
  pageSize = 50
  tableHeaders = [
    {
      link: true,
      key: 'name',
      label: 'Name'
    },
    {
      checkbox: {
        ignoreBy: 'type',
        ignore: [
          'AUTOPAY',
          'AUTOPAY_DISABLED',
          'AUTOPAY_DISABLED_MERCHANT',
          'AUTOPAY_MERCHANT',
          'PAYMENT_FAILED',
          'PAYMENT_PROMISE',
          'PAYMENT_PROMISE_CONFIRMED',
          'PAYMENT_SCHEDULED',
          'PAYMENTREQUEST',
          'THANKS',
          'WELCOME',
          'UNCONFIRMED_PROMISE'
        ]
      },
      key: 'active',
      label: 'Active'
    }
  ]
  totalMessageTemplates = 0
  totalPages: null | number = null

  mounted() {
    const navTitle = [{ crumbTitle: 'Templates' }]

    this.$store.dispatch('setPageTitle', navTitle)
    this.getMessageTemplates()
  }

  createTemplate() {
    this.$router.push({
      name: 'MessageTemplateEdit',
      params: { merchantID: this.$route.params.merchantID }
    })
  }

  async getMessageTemplates() {
    try {
      const response = await this.axios.get(
        endpoints.listMessageTemplates(this.$route.params.merchantID),
        {
          params: { page: this.currentPage, page_size: this.pageSize }
        }
      )
      this.messageTemplates = response.data.results
      this.totalMessageTemplates = response.data.count
    } catch (err) {
      console.log('error', err)
    } finally {
      this.isBusy = false
      this.totalPages = Math.ceil(this.totalMessageTemplates / this.pageSize)
    }
  }

  handlePage(newPage) {
    this.handlePageChange(newPage)
    this.getMessageTemplates()
  }

  handlePageSize(pageSize) {
    this.handlePageSizeChange(pageSize)
    this.getMessageTemplates()
  }

  toggleTemplateActivity(event) {
    try {
      this.axios.put(
        endpoints.updateMessageTemplate(
          this.$route.params.merchantID,
          event.id
        ),
        event
      )
    } catch (err) {
      console.log('error', err)
    }
  }

  get hasItems() {
    return this.messageTemplates.length >= 1
  }
}
