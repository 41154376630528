import moment from 'moment'
import getDefaultState from './defaultState'

export default {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setTokens(state, { accessToken, refreshToken }) {
    state.accessToken = accessToken
    state.refreshToken = refreshToken

    if (accessToken) {
      localStorage.setItem('blytzpay_access_token', accessToken)
    } else if (accessToken === null) {
      localStorage.removeItem('blytzpay_access_token')
    }

    if (refreshToken) {
      localStorage.setItem('blytzpay_refresh_token', refreshToken)
    } else if (refreshToken == null) {
      localStorage.removeItem('blytzpay_refresh_token')
    }
  },
  setLastActivity(state) {
    /*
     Sets the last activity as a utc unix timestamp
     This method should mostly be called from an axios interceptor
    */
    state.lastActivity = moment().utc().unix()
  },
  setIsInactive(state, isInactive) {
    state.isInactive = isInactive
  },
  setProfile(state, profile) {
    state.profile = profile
  },
  setHeartbeat(state, heartbeat) {
    state.heartbeat = heartbeat
  }
}
