const MerchantAccountListView = () =>
  import(
    '@/views/MerchantAccounts/MerchantAccountListView/MerchantAccountListView.vue'
  )
const MerchantAccountDetailView = () =>
  import(
    '@/views/MerchantAccounts/MerchantAccountDetailView/MerchantAccountDetailView.vue'
  )
const MerchantAccountCreateView = () =>
  import(
    '@/views/MerchantAccounts/MerchantAccountCreateView/MerchantAccountCreateView.vue'
  )

export default [
  {
    path: '/merchants/:merchantID?/locations/:locationID?/merchant-accounts',
    component: MerchantAccountListView,
    name: 'MerchantAccountList'
  },
  {
    path: '/merchants/:merchantID/locations/:locationID/merchant-accounts/create',
    component: MerchantAccountCreateView,
    name: 'MerchantAccountCreate'
  },
  {
    path: '/merchants/:merchantID/locations/:locationID/merchant-accounts/:merchantAccountID',
    component: MerchantAccountDetailView,
    name: 'MerchantAccountDetail'
  }
]
