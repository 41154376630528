






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import SnippetTrigger from '@/components/SnippetTrigger/SnippetTrigger/SnippetTrigger.vue'

export interface MessageTemplate {
  sms_body: string
  type: string
}

@Component({
  components: {
    SnippetTrigger
  }
})
export default class MessageTemplateEdit extends Vue {
  @Prop({ required: true }) messageTemplate!: MessageTemplate

  bodyLength = 0
  mergeField = null
  smsKey = 0

  @Watch('messageTemplate')
  setLength() {
    this.bodyLength = this.messageTemplate.sms_body?.length
  }

  allowPaymentLink(type) {
    // Following types generate payment link in template - prevent double links
    const exempt = ['PAYMENT_FAILED', 'PAYMENTREQUEST', 'SCHEDULED', 'WELCOME']

    return !exempt.includes(type)
  }

  mergeInput(value) {
    this.messageTemplate.sms_body = this.messageTemplate.sms_body + value
    this.smsKey++
  }

  templateBodyDraft(isDirty) {
    this.$emit('templateBodyDraft', isDirty)
  }

  get allowAutopaySnippet(): boolean {
    return (
      this.messageTemplate.type === 'AUTOPAY' ||
      this.messageTemplate.type === 'AUTOPAY_MERCHANT'
    )
  }

  get allowPaymentLinkSnippet(): boolean {
    // Following types generate payment link in template render - prevent double links
    const exempt = ['SCHEDULED', 'PAYMENTREQUEST', 'PAYMENT_FAILED']
    return !exempt.includes(this.messageTemplate.type)
  }

  get bodyCharLength(): string {
    return this.bodyLength > 290 ? 'text-danger' : ''
  }

  get dueDateSnippetLabel(): string {
    return this.messageTemplate.type === 'AUTOPAY' ? 'Next Date' : 'Due Date'
  }
}
