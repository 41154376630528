const MerchantListView = () =>
  import('@/views/Merchants/MerchantListView/MerchantListView.vue')
const MerchantDetailView = () =>
  import('@/views/Merchants/MerchantDetailView/MerchantDetailView.vue')
const DownPaymentView = () =>
  import('@/views/Merchants/DownPaymentView/DownPaymentView.vue')
const TransactionSearchView = () =>
  import('@/views/Reports//TransactionSearchView.vue')
const PaymentReportView = () =>
  import('@/views/Reports/TransactionReportView/TransactionReportView.vue')
const OldPaymentReportView = () =>
  import('@/views/Reports/PaymentReportView.vue')
const TransactionReportLegacyView = () =>
  import(
    '@/views/Reports/TransactionReportView/TransactionReportLegacyView.vue'
  )
// const Transactions = () =>
//   import('@/views/Reports/TransactionReportView/Transactions.vue')
const InterchangeQualificationReportView = () =>
  import('@/views/Reports/InterchangeQualificationReportView.vue')
const BlytzpayInvoiceDetails = () =>
  import('@/views/Reports/BlytzpayInvoiceDetails.vue')
const DepositReportView = () => import('@/views/Reports/DepositReportView.vue')
const ProjectionReportView = () =>
  import('@/views/Reports/ProjectionReportView.vue')
const SweepReportView = () => import('@/views/Reports/SweepReportView.vue')
const ActionReportView = () =>
  import('@/views/Reports/UserActionReportView/UserActionReportView.vue')
const MerchantCreateView = () =>
  import('@/views/Merchants/MerchantCreateView/MerchantCreateView')
const MerchantSettings = () =>
  import('@/views/Merchants/MerchantSettingsView/MerchantSettingsView.vue')
const ReconciliationDiff = () =>
  import(
    '@/views/Reports/Reconciliation/ReconciliationDiff/ReconciliationDiff.vue'
  )
const FailedActionsView = () =>
  import('@/views/Reports/FailedActions/FailedActions.vue')
const PromiseReportView = () =>
  import('@/views/Reports/PromiseReportView/PromiseReportView.vue')
const MessageReportView = () =>
  import('@/views/Reports/MessageReportView/MessageReportView.vue')
const MessageReportDetails = () =>
  import('@/views/Reports/MessageReportView/MessageReportDetails.vue')

const AchReturnReportView = () =>
  import('@/views/Reports/AchReturnReportView/AchReturnReportView.vue')

import settingRoutes from '@/router/settings/setting-routes'

export default [
  { path: '/merchants', component: MerchantListView, name: 'MerchantList' },
  {
    path: '/merchants/create',
    component: MerchantCreateView,
    name: 'MerchantCreate'
  },
  {
    path: '/merchants/:merchantID',
    component: MerchantDetailView,
    name: 'MerchantDetail'
  },
  {
    path: '/merchants/:merchantID/misc-payment/**',
    component: DownPaymentView,
    name: 'DownPaymentView'
  },
  {
    path: '/merchants/:merchantID/settings/',
    component: MerchantSettings,
    children: [...settingRoutes]
  },
  {
    path: '/transaction-search',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_merchant_payment_report_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: TransactionSearchView,
    name: 'TransactionSearch'
  },
  {
    path: '/transaction-report-legacy',
    component: TransactionReportLegacyView,
    name: 'TransactionReportLegacy'
  },
  {
    path: '/interchange-qualification-report',
    component: InterchangeQualificationReportView,
    name: 'InterchangeQualificationReport',
    meta: {
      require_roles: ['super_admin']
    }
  },
  {
    path: '/interchange-qualification-report/:blytzpayInvoiceID',
    component: BlytzpayInvoiceDetails,
    name: 'BlytzpayInvoiceDetails',
    meta: {
      require_roles: ['super_admin']
    }
  },
  {
    path: '/deposit-report',
    component: DepositReportView,
    name: 'DepositReport'
  },
  {
    path: '/payment-report',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_merchant_payment_report_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: PaymentReportView,
    name: 'PaymentReport'
  },
  {
    path: '/old-payment-report',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_merchant_payment_report_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: OldPaymentReportView,
    name: 'OldPaymentReport'
  },
  {
    path: '/projection-report',
    component: ProjectionReportView,
    name: 'ProjectionReport'
  },
  {
    path: '/sweep-report',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_merchant_sweep_report_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: SweepReportView,
    name: 'SweepReport'
  },
  {
    path: '/action-report',
    component: ActionReportView,
    name: 'ActionReport',
    meta: {
      require_roles: ['super_admin', 'admin', 'staff']
    }
  },
  {
    path: '/merchants/:merchantID/reconciliation',
    component: ReconciliationDiff,
    name: 'Reconciliation'
  },
  {
    path: '/merchants/:merchantID/failed-actions',
    component: FailedActionsView,
    name: 'FailedActions'
  },
  {
    path: '/promises',
    component: PromiseReportView,
    name: 'PromiseReportView'
  },
  {
    path: '/ach-return',
    component: AchReturnReportView,
    name: 'AchReturnReportView'
  },
  {
    path: '/message-report',
    component: MessageReportView,
    name: 'MessageReportView'
  },
  {
    path: '/message-report/:messageID/details',
    component: MessageReportDetails,
    name: 'MessageReportDetail'
  }
]
