import {
  faAddressCard,
  faAngleRight,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarTimes,
  faChartLine,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faCog,
  faCreditCard,
  faEnvelope,
  faEnvelopeSquare,
  faExclamationCircle,
  faExclamationTriangle,
  faFileDownload,
  faFilter,
  faHandHoldingUsd,
  faHandshake,
  faHandshakeSlash,
  faInfo,
  faLevelDownAlt,
  faLock,
  faMinusCircle,
  faMobileAlt,
  faMoneyBill,
  faPaperPlane,
  faPaperclip,
  faPen,
  faPlus,
  faSearch,
  faTachometerAlt,
  faTimesCircle,
  faUndoAlt,
  faUserTag,
  faUsers,
  faCommentSlash,
  faTrash,
  faUser,
  faUserSlash,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons'

import { faCommentDots } from '@fortawesome/free-regular-svg-icons'

import {
  faArrowToRight,
  faEnvelopeOpenDollar,
  faSpinnerThird,
  faUsdCircle,
  faUsdSquare,
  faCalendarCheck
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'

export default [
  faExclamationTriangle,
  faUndoAlt,
  faUserTag,
  faSpinnerThird,
  faPaperclip,
  faMobileAlt,
  faEnvelopeOpenDollar,
  faUsdSquare,
  faEnvelope,
  faArrowToRight,
  faCheckCircle,
  faAngleRight,
  faArrowToRight,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarTimes,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faChartLine,
  faCheckCircle,
  faChevronLeft,
  faCircle,
  faCog,
  faCreditCard,
  faEnvelope,
  faEnvelopeOpenDollar,
  faExclamationCircle,
  faFileDownload,
  faFilter,
  faFilter,
  faHandHoldingUsd,
  faInfo,
  faLevelDownAlt,
  faMobileAlt,
  faPaperPlane,
  faPen,
  faPlus,
  faSearch,
  faTachometerAlt,
  faTimesCircle,
  faUsdCircle,
  faCalendarAlt,
  faHandshake,
  faUsdSquare,
  faUsers,
  faHandshakeSlash,
  faLock,
  faAddressCard,
  faMinusCircle,
  faChevronDown,
  faEnvelopeSquare,
  faMoneyBill,
  faCommentSlash,
  faTrash,
  faGripVertical,
  faCalendarCheck,
  faUser,
  faUserSlash,
  faCommentDots
]
