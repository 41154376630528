



















import { Component, Vue } from 'vue-property-decorator'

const Sidebar = () => import('@/components/Navigation/Sidebar/Sidebar.vue')
const Navbar = () => import('@/components/Navigation/Navbar/Navbar.vue')

type ClassName = string | string[]

Component.registerHooks(['beforeRouteUpdate'])
@Component({
  components: {
    Sidebar,
    Navbar
  }
})
export default class ContainerWithNav extends Vue {
  cardBodyClass: ClassName = []

  created() {
    this.$eventBus.on('setCardBodyClass', (className: ClassName) => {
      Array.isArray(className)
        ? (this.cardBodyClass = className)
        : (this.cardBodyClass = [className])
    })
  }

  destroyed() {
    this.$eventBus.off('setCardBodyClass')
  }
}
