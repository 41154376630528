import moment from 'moment'

export function standardFormatter(value: string): string {
  return value ? value : '-'
}
export function basisPointsFormatter(amount: string): string {
  return (Number(amount) / 100).toString() + '%'
}
export function moneyFormatter(amount: string): string {
  if (amount) {
    amount = parseFloat(amount).toFixed(2).toString()
    if (amount.split('.').length === 1) {
      amount = amount + '.00'
    }

    return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '$0.00'
  }
}
export function numberFormatter(number: string): string {
  if (number) {
    number = number.toString()
    if (number.split('.')[1]?.length === 1) {
      number = number + '0'
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '0'
  }
}
export function dateFormatter(date: string): string {
  return date ? moment(date, 'YYYY/MM/DD').format('MM/DD/YYYY') : '-'
}
export function dateTimeLocalFormatter(date: string): string {
  return date ? moment(date).local().format('L LT') : '-'
}
export function percentageFormatter(percentage: string): string {
  return percentage
    ? percentage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
    : '-'
}
export function ssnFormatter(ssn: string): string {
  if (ssn) {
    ssn = ssn.replace(/\D/g, '')
    ssn = ssn.replace(/^(\d{3})/, '$1-')
    ssn = ssn.replace(/-(\d{2})/, '-$1-')
    ssn = ssn.replace(/(\d)-(\d{4}).*/, '$1-$2')
    return ssn
  } else {
    return '-'
  }
}
export function phoneFormatter(phoneNum: string): string {
  if (phoneNum) {
    phoneNum = ('' + phoneNum).replace(/\D/g, '')
    const match = phoneNum.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlNum = match[1] ? '+1 ' : ''
      return [intlNum, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNum
  } else {
    return '-'
  }
}
export function phoneReformatter(phoneNum: string): string {
  if (phoneNum) {
    return phoneNum.replace(/\(|\)|\s|-/g, '')
  } else {
    return phoneNum
  }
}
export function promiseFormatter(promise: string): string {
  if (promise === 'PROMISE_CONFIRMED' || promise === 'SCHEDULED') {
    return 'Confirmed'
  } else if (promise === 'PROMISED') {
    return 'Unconfirmed'
  } else {
    return '-'
  }
}
