import { AdhocClaimsInterface } from '@/utils/interfaces'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['accessClaims'])
  }
})
export class AuthMixin extends Vue {
  accessClaims!: AdhocClaimsInterface

  hasPermission(roles) {
    return roles.some(r => this.accessClaims?.roles?.includes(r))
  }
}
