var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"standard-page v-flex"},[_c('b-card',{staticClass:"update-message-template-content"},[_c('ValidationObserver',{ref:"observer",staticClass:"view-form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('b-card-body',{staticClass:"message-template-body"},[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{staticClass:"message-template-body-col",attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"top-whitespace"},[(_vm.isSpecial(_vm.messageTemplate.type))?_c('div',[_c('label',[_vm._v(" "+_vm._s(_vm.displayName(_vm.messageTemplate.type))+" ")]),_c('p',{staticClass:"based-on-label"},[_vm._v(" "+_vm._s(_vm.messageTemplate.description)+" ")])]):_vm._e()]),_c('label',[_vm._v("TEMPLATE NAME")]),_c('p',{staticClass:"based-on-label"},[_vm._v(" This name will also be used as the subject for automated email messages. ")]),_c('div',{staticClass:"subject-input"},[_c('TextInput',{staticClass:"name-input",attrs:{"name":"name","errors":errors,"rules":"required","cy":"subject"},on:{"input":_vm.setDraft},model:{value:(_vm.messageTemplate.name),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "name", $$v)},expression:"messageTemplate.name"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v("MESSAGE")])])],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"based-on-label"},[_vm._v(" Write your message in the box provided below. You may add substitution codes to your template by choosing from the list provided. Click the Preview buttons to see an example of what your customers will see. Please note, a custom payment link will automatically be added to all templates (see preview for example). ")])])],1),_c('b-row',[_c('b-col',{staticClass:"view-togglers"},[_c('p',{staticClass:"view-togglers-label"},[_vm._v("Preview:")]),_c('router-link',{attrs:{"to":{
                    name: 'MessageTemplateEdit',
                    params: Object.assign({}, _vm.$route.params)
                  }}},[_c('b-badge',{staticClass:"switch-badge",attrs:{"variant":_vm.isCurrentRoute('MessageTemplateEdit')}},[_vm._v(" Edit ")])],1),_c('router-link',{attrs:{"to":{
                    name: 'MessageTemplateText',
                    params: Object.assign({}, _vm.$route.params)
                  }}},[_c('b-badge',{staticClass:"switch-badge",attrs:{"variant":_vm.isCurrentRoute('MessageTemplateText')}},[_vm._v(" Text ")])],1),_c('router-link',{attrs:{"to":{
                    name: 'MessageTemplateEmail',
                    params: Object.assign({}, _vm.$route.params)
                  }}},[_c('b-badge',{staticClass:"switch-badge",attrs:{"variant":_vm.isCurrentRoute('MessageTemplateEmail')}},[_vm._v(" Email ")])],1)],1)],1),_c('b-row',{staticClass:"router-container"},[_c('b-col',[_c('router-view',{attrs:{"message-template":_vm.messageTemplate},on:{"templateBodyDraft":_vm.setDraft}})],1)],1),(!_vm.isSpecial(_vm.messageTemplate.type))?_c('b-row',[_c('b-col',[_c('label',[_vm._v("AUTOMATION")]),_c('div',[_c('b-row',[_c('p',{staticClass:"based-on-label ml-3"},[_vm._v(" Choose an automation schedule for this template. ")])]),_c('b-row',[_c('b-col',{staticClass:"based-on-container"},[_c('SelectInput',{staticClass:"days-in-relation-select",attrs:{"name":"delta_days","errors":errors,"options":_vm.notifyOptions,"data-cy":"delta-days-selection"},on:{"input":_vm.updateDays},model:{value:(_vm.dayInRelation),callback:function ($$v) {_vm.dayInRelation=$$v},expression:"dayInRelation"}}),(_vm.dayInRelation === 1 || _vm.dayInRelation === -1)?_c('div',{staticClass:"days-container"},[_c('TextInput',{staticClass:"based-on-days-input",attrs:{"errors":[],"data-cy":"days-input"},on:{"input":function($event){_vm.setDraft()
                            _vm.updateType()}},model:{value:(_vm.messageTemplate.delta_days),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "delta_days", _vm._n($$v))},expression:"messageTemplate.delta_days"}}),_c('p',{staticClass:"based-on-days-label"},[_vm._v("Days")])],1):_vm._e()],1)],1)],1)])],1):_vm._e()],1)],1)],1),_c('b-card-footer',{staticClass:"create-footer"},[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"md":"6","sm":"8"}},[_c('p',[_vm._v(" By clicking save, you confirm that you have received verbal consent to send text messages and emails to your customers. ")])]),_c('b-col',{staticClass:"cancel",attrs:{"md":"1","sm":"2"}},[_c('a',{on:{"click":_vm.handleCancel}},[_c('span',[_vm._v("Cancel")])])]),_c('b-col',{attrs:{"md":"1","sm":"2"}},[_c('b-button',{staticClass:"save",attrs:{"disabled":_vm.isBusy || invalid || _vm.isSaveDisabled,"type":"submit","data-cy":"template-submit-button"}},[(!_vm.isBusy)?_c('div',[_vm._v("Save")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }