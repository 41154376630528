import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const state = {
  numMerchants: 0,
  currentMerchant: null,
  merchants: [],
  merchantLoading: false,
  numCustomers: 0,
  currentCustomer: {},
  customers: [],
  customerLoading: false,
  recentPaymentLoading: false,
  numAccounts: 0,
  accounts: [],
  accountLoading: false,
  transactionReportURL: '',
  pageTitle: [],
  csvData: {},
  isRefreshComplete: false,
  currentAccount: {},
  locations: [],
  currentLocation: {},
  selectedLocation: {},
  currentMerchantAccount: {},
  group: null,
  users: [],
  oauthLinks: {},
  accountTags: [],
  failedActions: [],
  receiptModalOpen: false,
  currentPayment: null,
  accountPromises: [],
  accountPromisesLoading: false,
  merchantAccounts: [],
  merchantAccountsLoading: false,
  binInfo: {},
  binInfoLoading: false,
  roleLoading: false,
  role: {},
  dashboardDateRange: {}
}

export default {
  state: () => state,
  mutations,
  actions,
  getters
}
