import LoginView from '@/views/Auth/LoginView/LoginView.vue'

const InviteRegisterView = () =>
  import('@/views/Auth/InviteRegisterView/InviteRegisterView.vue')
const ExistingUserRegistrationView = () =>
  import(
    '@/views/Auth/ExistingUserRegistrationView/ExistingUserRegistrationView.vue'
  )
const ForgotPasswordView = () =>
  import('@/views/Auth/ForgotPasswordView/ForgotPasswordView.vue')
const ResetPasswordView = () =>
  import('@/views/Auth/ResetPasswordView/ResetPasswordView.vue')
const UnAuthorizedView = () =>
  import('@/views/Auth/UnAuthorizedView/UnAuthorizedView.vue')
const FreshBooksAuthView = () =>
  import('@/views/Auth/FreshBooksAuthView/FreshBooksAuthView')

export default [
  {
    path: '/login',
    component: LoginView,
    name: 'Login',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/sso',
    component: LoginView,
    name: 'SSO Login',
    meta: { publicRoute: true, hideNav: true, isSSO: true }
  },
  {
    path: '/partner-invite/:id/:token',
    component: ExistingUserRegistrationView,
    name: 'ExistingUserRegistration',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/partner-signup/:id/:token',
    component: InviteRegisterView,
    name: 'InviteRegister',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordView,
    name: 'ForgotPassword',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/reset-password/:id/:token',
    component: ResetPasswordView,
    name: 'ResetPassword',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/unauthorized',
    component: UnAuthorizedView,
    name: 'UnAuthorized',
    meta: { publicRoute: true, hideNav: true }
  },
  {
    path: '/freshbooks/auth/:code',
    component: FreshBooksAuthView,
    name: 'FreshBooksAuth'
  }
]
