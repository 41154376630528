<template>
  <b-card id="chat-window" class="v-flex" no-body>
    <b-input-group id="search-chat-group" class="mb-3" size="sm">
      <b-form-input
        v-model="search"
        placeholder="Search chats..."
        type="text"
        data-cy="search-chat"
      />
      <b-input-group-append is-text>
        <b-icon icon="search" />
      </b-input-group-append>
    </b-input-group>

    <b-card-body id="chat-messages" ref="messageList" class="v-flex rounded-0">
      <infinite-loading
        direction="top"
        :identifier="infiniteId"
        @infinite="load"
      >
        <template #no-more>
          <span class="no-more">Beginning of Conversation</span>
          <hr />
        </template>
      </infinite-loading>

      <div
        v-for="(message, index) of messages"
        :key="message.id"
        class="message"
        :class="{
          error: !!message.error,
          outbound: message.sender,
          inbound: !message.sender,
          later: isLater(message, index)
        }"
      >
        <hr
          v-if="!isSame(message, index, 'day')"
          class="hr-text"
          :data-content="message.created.format('LL')"
        />

        <p v-if="isLater(message, index)" class="message-info">
          <b v-if="!message.sender">
            {{ customer.first_name }}
          </b>

          <b v-else-if="isDifferentSender(message, index)">
            {{ message.sender.first_name }}
          </b>

          <small>
            {{ message.created | moment('ddd h:mm A') }}
          </small>
        </p>

        <span class="h-flex vertical-center">
          <b-spinner
            v-if="message.pending && message.sender"
            small
            variant="light"
          />
          <fa-icon
            v-else-if="message.error && message.sender"
            class="message-error"
            icon="exclamation-circle"
          />

          <div v-if="message.text">
            {{ translated(message) }}
          </div>

          <b-spinner
            v-if="message.pending && !message.sender"
            small
            variant="light"
          />
          <fa-icon
            v-else-if="message.error && !message.sender"
            class="message-error"
            icon="exclamation-circle"
          />
        </span>

        <small v-if="message.errorMessage" class="errors">
          {{ message.errorMessage }}
        </small>

        <span v-if="message.attachments && message.attachments.length">
          <span v-for="attachment of message.attachments" :key="attachment.url">
            <div v-if="vcard(attachment.type)" class="vcard">
              <a :href="attachment.url">
                <fa-icon :icon="['fas', 'address-card']" />
              </a>
            </div>
            <div v-else class="attachment">
              <img :src="attachment.url" @click="openImage(attachment.url)" />
            </div>
          </span>
        </span>
      </div>
    </b-card-body>

    <template #footer>
      <div v-if="accountId" id="snippet-triggers" class="rack compress">
        <b-dropdown
          id="message-templates"
          :disabled="renderTemplateBusy"
          dropup
        >
          <template #button-content>
            <fa-icon
              v-if="!renderTemplateBusy"
              icon="envelope-square"
              data-cy="message-template-access"
            />
            <span v-else class="spinner">
              <b-spinner small />
            </span>
          </template>
          <b-dropdown-group
            v-for="message in messageTemplates"
            :key="message.text"
            @click="messageTemplatesSelected(message.value)"
          >
            {{ message.text }}
          </b-dropdown-group>
        </b-dropdown>

        <SnippetTrigger
          label="Payment Link"
          type="dark"
          data-cy="payment-link-snippet"
          @click.native="addPaymentLink"
        />

        <div>
          <b-button
            v-if="!canFreeType"
            id="clear-chat"
            small
            @click="clearChat"
          >
            <fa-icon icon="times-circle" />
          </b-button>
        </div>

        <b-form-group v-if="languages.size > 1">
          <b-form-select
            id="language"
            v-model="language"
            size="sm"
            :options="options(languages)"
          />
        </b-form-group>
      </div>

      <b-form
        id="chat-input"
        :class="{ 'chat-input-disabled': !canFreeType }"
        @keyup="handleKey"
        @change="handleChange"
      >
        <b-textarea
          id="chat-input-text"
          v-model="messageBody"
          :placeholder="chatDisplay"
          no-resize
          :disabled="!canFreeType"
        />

        <b-button id="chat-send" data-cy="send-chat-customer" @click="send">
          <fa-icon icon="paper-plane" />
        </b-button>

        <div class="actions">
          <label class="btn btn-sm" for="attach-file">
            <fa-icon :icon="['fas', 'paperclip']" />
          </label>
        </div>

        <div v-if="file" class="img-preview">
          <b-button size="sm" @click="clearAttachment">
            <fa-icon :icon="['fas', 'times-circle']" />
          </b-button>
          <img v-if="fileUrl" :src="fileUrl" />
          <fa-icon v-else spin :icon="['fas', 'spinner-third']" />
        </div>

        <div style="display: none">
          <b-form-file
            id="attach-file"
            v-model="file"
            accept="image/*, .pdf"
            hidden
            @input="uploadMedia"
          />
        </div>
      </b-form>
    </template>
  </b-card>
</template>

<script>
import { AuthMixin } from '@/mixins/AuthMixin/AuthMixin'
import axios from '@/utils/init-axios.js'
import endpoints from '@/utils/endpoints.js'
import { debounce, uniqBy } from 'lodash-es'
import moment from 'moment'
import ReconnectingWebSocket from 'reconnecting-websocket'
import SnippetTrigger from '@/components/SnippetTrigger/SnippetTrigger/SnippetTrigger.vue'
import InfiniteLoading from 'vue-infinite-loading'

import {
  Component,
  VModel,
  Mixins,
  Watch,
  Prop,
  Vue
} from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

@Component({
  computed: {
    ...mapGetters(['currentAccount'])
  },
  components: {
    InfiniteLoading,
    SnippetTrigger
  }
})
export default class Chat extends Mixins(AuthMixin) {
  @VModel() customer
  @Prop({ type: String, default: '' }) accountId

  file = null
  fileUrl = null
  hasDraft = false
  infiniteId = +new Date()
  language = 'en-US'
  languages = new Set(['en-US'])
  messageBody = ''
  messageTemplates = []
  messages = []
  page = 1

  renderTemplateBusy = false
  search = null
  socket = null

  async addPaymentLink() {
    const accountId = this.accountId
    const merchantID = this.$route.params.merchantID
    const response = await axios.post(
      this.$endpoints.createAccountPaymentLink(merchantID, accountId),
      {
        customer_id: this.customer.id
      }
    )
    const paymentLink = response.data.payment_link
    this.messageBody = this.messageBody + paymentLink
  }

  async messageTemplatesSelected(event) {
    this.renderTemplateBusy = true

    try {
      const response = await this.axios.post(
        endpoints.renderMessageTemplate(
          this.$route.params.merchantID,
          event.id
        ),
        {
          customer: this.customer.id,
          account: this.currentAccount.id || this.customer.accounts[0].id,
          type: 'TEXT'
        }
      )

      this.messageBody = this.messageBody + response.data.rendered_body + ' '
    } catch (err) {
      console.log('error', err)
    } finally {
      this.renderTemplateBusy = false
    }
  }

  created() {
    this.initiateConnections()
    this.getMessageTemplates()
  }

  @Watch('customer', { deep: true })
  onCustomerChange() {
    this.search = null
    this.clear()
    this.initiateConnections()
  }

  clear() {
    this.page = 1
    this.infiniteId += 1
    this.messages = []
  }

  @Watch('$route.params.customerID')
  clearChat() {
    this.messageBody = ''
  }

  beforeDestroy() {
    if (this.socket) this.socket.close()
  }

  get canFreeType() {
    if (this.$store.getters.currentMerchant.check_custom_roles) {
      return this.hasPermission([
        'super_admin',
        'admin',
        'is_customer_free_text_allowed'
      ])
    } else {
      return this.hasPermission(['super_admin', 'admin', 'staff'])
    }
  }

  connect() {
    if (this.socket) this.socket.close()

    const base = window.VUE_APP_WS_URL
    const id = this.customer.id
    const token = this.$store.getters.accessToken
    const url = `${base}/ws/chat/${id}/?token=${token}`

    this.socket = new ReconnectingWebSocket(url)
    this.socket.onmessage = event => {
      const message = JSON.parse(event.data).message
      this.messages.push(this.parseMessage(message))
    }
  }

  clearAttachment() {
    this.file = null
    this.fileUrl = null
  }

  async getMessageTemplates() {
    try {
      const response = await this.axios.get(
        endpoints.listMessageTemplates(this.$route.params.merchantID),
        {
          params: { page: 1, page_size: 1000 }
        }
      )
      this.messageTemplates = response.data.results.map(template => {
        return { value: template, text: template.name }
      })
    } catch (err) {
      console.log('error', err)
    }
  }

  handleKey(event) {
    this.hasDraft = this.messageBody !== ''

    if (event.key === 'Enter' && !event.shiftKey) {
      this.send()
      event.preventDefault()
    }
  }

  handleChange() {
    this.hasDraft = this.messageBody !== ''
    this.$emit('chatDraft', this.hasDraft)
  }

  initiateConnections() {
    if (!this.customer?.id) return
    this.languages = new Set(['en-US'])

    this.connect()
  }

  isDifferentSender(message, index) {
    if (index === 0) return true

    const lastMessage = this.messages[index - 1]
    return message?.sender?.id !== lastMessage?.sender?.id
  }

  isLater(message, index) {
    if (index == 0) return true

    const lastMessage = this.messages[index - 1]
    const minutes = message.created.diff(lastMessage.created, 'minutes')
    return minutes > 5
  }

  isSame(message, index, unit) {
    if (index == 0) return false

    const lastMessage = this.messages[index - 1]
    return message.created.isSame(lastMessage.created, unit)
  }

  async load(state) {
    try {
      if (!this.customer?.id) {
        return
      }
      const response = await this.axios.get(
        endpoints.listCustomerMessages(
          this.$route.params.merchantID,
          this.customer.id
        ),
        {
          params: {
            page: this.page,
            page_size: 25,
            ...(this.search && { search: this.search.trim() })
          }
        }
      )

      const parsed = response.data.results
        .map(message => this.parseMessage(message))
        .reverse()

      this.messages = uniqBy([...parsed, ...this.messages], 'id')
      this.page += 1
      state.loaded()

      if (!response.data.next) state.complete()
    } catch {
      state.complete()
    }
  }

  @Watch('search')
  debouncedClear = debounce(async () => {
    this.clear()
  }, 500)

  parseMessage(message) {
    const parsed = {
      id: message.id,
      sender: message.sender,
      text: message.body,
      language: message.language,
      translations: message.translations,
      created: moment(message.created),
      attachments: message?.attachments,
      error: message?.error_code,
      errorMessage: message?.error_message,
      secondaryMessage: message?.secondary_message
    }

    this.languages.add(parsed.language)

    Object.entries(message.translations).forEach(([language]) => {
      this.languages.add(language)
    })

    return parsed
  }

  options(values) {
    const languages = [...values]
    const names = new Intl.DisplayNames(languages, { type: 'language' })
    const options = languages.reduce((current, language) => {
      current.push({ value: language, text: names.of(language) })
      return current
    }, [])

    return options
  }

  async send() {
    // Don't let them spam the button.
    const text = this.messageBody.trim()
    if (!(text && text.length > 0) && !this.fileUrl) return

    let message = {
      id: null,
      sender: 'me',
      text: text,
      language: 'en-US',
      created: moment(),
      translations: {},
      pending: true
    }

    this.messageBody = ''
    const index = this.messages.push(message) - 1

    this.hasDraft = false
    this.$emit('chatDraft', this.hasDraft)

    // Silly workaround for how the chat API is currently implemented.
    const response = await axios.post(
      endpoints.sendMessage(this.$route.params.merchantID, this.accountId),
      {
        customer: this.customer.id,
        message: text,
        file_url: this.fileUrl,
        content_type: this.file ? this.file.type : null
      }
    )

    this.file = null
    this.fileUrl = null

    message = response.data[this.customer.id]
    const parsed = this.parseMessage(message)
    Vue.set(this.messages, index, parsed)

    this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight
  }

  translated(message) {
    return this.language == message.language
      ? message.text
      : message.translations[this.language] ?? message.text
  }

  openImage(url) {
    window.open(url, '_blank')
  }

  async uploadMedia() {
    this.fileUrl = null
    const file = this.file
    if (file === null) return

    // in MiB
    const fileSize = this.file.size / 1024 / 1024
    if (1 < fileSize) {
      this.file = null
      this.fileUrl = null
      this.$toasted.global.error({
        message: 'File must be less than 1mb in size'
      })
      return
    }
    // Silly workaround for how the chat API is currently implemented.
    const accountId = this.customer.accounts[0].id
    const formData = new FormData()
    formData.append('file', this.file)
    const response = await axios.post(
      endpoints.uploadMMSMedia(this.$route.params.merchantID, accountId),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    this.fileUrl = response.data.media_path
  }

  vcard(type) {
    return type === 'text/vcard' || type === 'text/x-vcard'
  }

  get chatDisplay() {
    return this.canFreeType
      ? 'Type here to chat...'
      : 'Choose a template to chat...'
  }
}
</script>

<style lang="scss">
#message-templates {
  svg,
  .spinner {
    width: 2rem;
    height: 1.5rem;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1em 0.25em;

    background: $steel-gray;
  }
  ul {
    max-height: 20rem;
    overflow: scroll;

    font-size: 1em;

    background: $lighter-gray;

    li,
    ul {
      padding-left: 0.5em;

      &:hover {
        background: $sms-header-end-gradient;
        cursor: pointer;
      }
    }
  }
}

#clear-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1em;

  background: #8193a9;
  border-radius: 100%;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>

<style lang="scss" scoped>
#search-chat-group {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;

  width: 75%;
  max-width: 300px;
  * {
    color: white;

    background: #373c42;
    border-color: white;
  }

  input {
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      color: #ccc;
    }
  }

  .input-group-append {
    border-left-color: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

#snippet-triggers {
  font-size: 0.9rem;
}

.message-info {
  color: white;
}

#chat-header {
  align-items: center;
  justify-content: space-between;

  * {
    padding-bottom: none;
  }
}

#chat-window {
  width: 100%;
  max-height: 100%;

  border-style: none;
  border-top: 1px solid gray;

  .no-more {
    color: white;
  }

  .card {
    flex: 1;
    max-height: 100%;

    border-style: none;
    border-radius: 0;

    .card-header {
      padding: 0.25em;

      color: $blue;

      background: $customer-detail-light-bg;
      border-bottom-style: none;
    }
  }

  .card-body {
    height: 100%;
    overflow: hidden;

    background: $customer-detail-light-bg;
  }

  .card-footer {
    height: 330px;
    padding: 24px 30px 65px 24px;

    background: #455567;
    border-top: 1px solid #6a84a0;
  }

  #chat-input {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-direction: column;
    height: 100%;

    background-color: #373c42;
    border-radius: 0.25rem;

    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    #chat-input-text {
      flex: 1;
      width: calc(100% - 2rem);
      overflow: auto;

      color: white;
      font-size: 14px;
      line-height: 1.33;
      white-space: pre-wrap;
      word-wrap: break-word;

      background-color: transparent;
      border-style: none;
      outline: none;
      -webkit-font-smoothing: antialiased;

      &::placeholder {
        color: #ccc;
      }
    }

    #chat-input-text:empty::before {
      display: block;

      outline: none;
      cursor: text;
      filter: contrast(15%);

      content: attr(placeholder);
    }

    button#chat-send {
      align-self: center;
      margin-right: 0.5em;
      margin-left: auto;

      background-color: $button-blue;
      border-radius: 50%;
    }

    .img-preview {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.125rem;
      height: 3.125rem;
      margin: 0.1rem;
      margin-top: 0.5rem;

      color: $customer-detail-text-color;

      border: 1px solid $customer-detail-text-color;
      border-radius: 0.2rem;

      .btn {
        position: absolute;
        top: 0;
        right: 0;

        color: $customer-detail-error-text-color;

        background-color: transparent;
        border-style: none;
        transform: translate(50%, -50%);

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
      }
    }

    .actions {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 1rem;

      .btn {
        color: $customer-detail-text-color;

        background-color: rgba(255, 255, 255, 0);
        border-color: transparent;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .chat-input-disabled {
    background: transparent !important;
  }

  #chat-messages {
    $bg: darken($background, 2%);
    $dark-bg: darken($bg, 4%);
    max-height: 100%;
    overflow: auto;

    background: $customer-detail-light-bg;
    box-shadow: inset 0 0 30px #334048;

    content: '';

    img {
      width: 80%;
      height: 80px;
      object-fit: contain;
    }

    .message {
      width: 100%;
    }

    .message-error {
      color: $customer-detail-error-text-color;
    }

    %message {
      color: black;

      div {
        display: flex;
        width: fit-content;
        max-width: 320px;
        margin: 0.25rem;
        margin-top: 0;
        padding: 0.25rem 0.5rem;

        font-size: 0.9em;
        white-space: wrap;
        word-break: break-word;

        border-radius: 0.5rem;
      }

      p {
        margin: 0;

        font-size: 0.8em;

        cursor: default;
      }

      small {
        margin-bottom: 10px;
      }

      .vcard {
        font-size: 2.25em;
      }
    }

    div.attachment {
      display: flex;

      cursor: pointer;
    }
    .later {
      margin-top: 0.5em;
    }

    .outbound {
      @extend %message;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-items: flex-end;

      div.attachment {
        justify-content: flex-end;
      }

      div:not(.attachment) {
        background: rgb(218, 236, 201);
        background: linear-gradient(
          180deg,
          rgba(218, 236, 201, 1) 0%,
          rgba(175, 205, 160, 1) 100%
        );
      }

      p {
        margin-right: 5px;

        text-align: end;
      }

      small {
        text-align: end;
      }
    }

    .inbound {
      @extend %message;
      align-items: flex-start;

      div.attachment {
        justify-content: flex-start;
      }

      div:not(.attachment) {
        background: rgb(201, 230, 236);
        background: linear-gradient(
          180deg,
          rgba(201, 230, 236, 1) 0%,
          rgba(160, 186, 205, 1) 100%
        );
      }

      p {
        margin-left: 5px;
      }
    }
  }
}

#chat-send {
  position: absolute;
  top: 50%;
  left: 100%;

  width: 2.5rem;
  height: 2.5rem;

  transform: translate(-50%, -50%);

  svg {
    margin-left: -1px;
  }
}

.hr-text {
  position: relative;

  width: 100%;

  height: 1.5em;

  line-height: 1em;
  text-align: center;

  border: 0;
  outline: 0;
  opacity: 0.5;

  &::before {
    position: absolute;

    top: 50%;
    left: 0;

    display: inline-block;

    width: 100%;
    height: 1px;

    background: linear-gradient(to right, transparent, white, transparent);

    content: '';
  }

  &::after {
    position: relative;

    display: inline-block;

    padding: 0 0.5em;

    color: white;
    line-height: 1.5em;

    background-color: $customer-detail-light-bg;

    content: attr(data-content);
  }
}
</style>
