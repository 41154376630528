const Dashboard = () => import('@/views/Dashboard/Dashboard/Dashboard.vue')
const FailedPaymentsView = () =>
  import('@/views/Dashboard/FailedPaymentsView/FailedPaymentsView.vue')
const MissedMessagesView = () =>
  import('@/views/Dashboard/MissedMessagesView/MissedMessagesView.vue')
const AutoMessagesView = () =>
  import('@/views/Dashboard/AutoMessagesView/AutoMessagesView.vue')
const UnreadMessagesView = () =>
  import('@/views/Dashboard/UnreadMessagesView/UnreadMessagesView.vue')

export default [
  {
    path: '/',
    component: Dashboard,
    name: 'Home'
  },
  {
    path: '/failed-payments',
    component: FailedPaymentsView,
    name: 'FailedPayments'
  },
  {
    path: '/missed-messages',
    component: MissedMessagesView,
    name: 'MissedMessages'
  },
  {
    path: '/auto-messages',
    component: AutoMessagesView,
    name: 'AutoMessages'
  },
  {
    path: '/customer-replies',
    component: UnreadMessagesView,
    name: 'UnreadMessages'
  }
]
