import Vue from 'vue'
import Vuex from 'vuex'
import authStore from './authstore'
import dataStore from './datastore'

Vue.use(Vuex)
// Set default State
export const state = {
  isSidebarOpen: true
}

export const mutations = {
  setIsSidebarOpen(state, isOpen) {
    state.isSidebarOpen = isOpen
  }
}

export const actions = {}

export const getters = {}

export const modules = { authStore, dataStore }

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
