export class SilentError extends Error {
  // Hides error from User
  isSilent = true
  constructor(message) {
    super(message)
    this.name = 'SilentError'
  }
}

export class LocalError extends Error {
  // Hides error from User and Sentry
  isSilent = true
  constructor(message) {
    super(message)
    this.name = 'LocalError'
  }
}
