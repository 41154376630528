const OwnerListView = () =>
  import('@/views/Owners/OwnerListView/OwnerListView.vue')
const OwnerDetailView = () =>
  import('@/views/Owners/OwnerDetailView/OwnerDetailView.vue')
const OwnerCreateView = () =>
  import('@/views/Owners/OwnerCreateView/OwnerCreateView.vue')

export default [
  {
    path: '/merchants/:merchantID?/owners',
    component: OwnerListView,
    name: 'OwnerList'
  },
  {
    path: '/merchants/:merchantID/owners/create',
    component: OwnerCreateView,
    name: 'OwnerCreate'
  },
  {
    path: '/merchants/:merchantID/owners/:ownerID',
    component: OwnerDetailView,
    name: 'OwnerDetail'
  }
]
