<template>
  <div class="trigger">
    <div :class="type">
      <span class="trigger-elem">
        {{ label }}
      </span>
      <span class="trigger-elem drop-in-icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style lang="scss" scoped>
.trigger {
  > div {
    margin-bottom: 10px;
    padding: 12px 17px 14px;

    color: white;
    line-height: 0;
    line-height: 0;

    background-color: #b5b5b5;
    border: 1px solid #bfbfbf;
    border-radius: 100px;
    border-radius: 100px;
    cursor: pointer;

    .trigger-elem {
      display: inline-block;
    }
  }

  > div.dark {
    color: white;

    background-color: #373c42;
    border-color: #6a8298;
  }
}

.drop-in-icon {
  position: relative;

  width: 12px;
  height: 3px;
  margin-left: 5px;

  vertical-align: 5px;

  background-color: white;

  &::before {
    position: absolute;
    left: 9px;

    width: 4px;
    height: 6px;

    background-color: white;

    content: '';
  }

  &::after {
    position: absolute;
    top: 6px;
    left: 4px;

    border-top: 6px solid white;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;

    content: '';
  }
}
</style>
