export default {
  numMerchants(state) {
    return state.numMerchants
  },
  currentMerchant(state) {
    return state.currentMerchant
  },
  isMerchantLoading(state) {
    return state.merchantLoading
  },
  merchantPage(state) {
    return state.merchants
  },
  customerPage(state) {
    return state.customers
  },
  isCustomerLoading(state) {
    return state.customerLoading
  },
  isRecentPaymentLoading(state) {
    return state.recentPaymentLoading
  },
  numCustomers(state) {
    return state.numCustomers
  },
  accountPage(state) {
    return state.accounts
  },
  isAccountLoading(state) {
    return state.accountLoading
  },
  numAccounts(state) {
    return state.numAccounts
  },
  transactionReportURL(state) {
    return state.transactionReportURL
  },
  pageTitle(state) {
    return state.pageTitle
  },
  csvData(state) {
    return state.csvData
  },
  currentCustomer(state) {
    return state.currentCustomer
  },
  currentAccount(state) {
    return state.currentAccount
  },
  locations(state) {
    return state.locations
  },
  merchantAccounts(state) {
    return state.merchantAccounts
  },
  currentLocation(state) {
    return state.currentLocation
  },
  selectedLocation(state) {
    return state.selectedLocation
  },
  currentMerchantAccount(state) {
    return state.currentMerchantAccount
  },
  accountTags(state) {
    return state.accountTags
  },
  group(state) {
    return state.group
  },
  users(state) {
    return state.users
  },
  oauthLinks(state) {
    return state.oauthLinks
  },
  autoResolvableActionsCount(state) {
    return state.failedActions.filter(action => action.is_resolvable).length
  },
  humanResolvableActionsCount(state) {
    return state.failedActions.filter(action => !action.is_resolvable).length
  },
  roleLoading(state) {
    return state.roleLoading
  },
  role(state) {
    return state.role
  },
  dashboardDateRange(state) {
    return state.dashboardDateRange
  }
}
