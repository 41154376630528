import { capitalize } from 'lodash-es'

export function title(str: string) {
  return str?.split(' ').map(capitalize).join(' ')
}

// Shamelessly stolen from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export function formatPhone(number: string) {
  const cleaned = ('' + number).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return number
}
