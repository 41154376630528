export default {
  methods: {
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize
      this.currentPage = 1
    },
    handlePageChange(newPage) {
      this.currentPage = newPage
    }
  }
}
