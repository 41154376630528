import MessageTemplateEdit from '@/components/MessageTemplate/MessageTemplateEdit/MessageTemplateEdit'
import MessageTemplateEmail from '@/components/MessageTemplate/MessageTemplateEmail/MessageTemplateEmail'
import MessageTemplateText from '@/components/MessageTemplate/MessageTemplateText/MessageTemplateText'
import MessageTemplateListView from '@/views/MessageTemplates/MessageTemplateListView/MessageTemplateListView.vue'
import UpdateMessageTemplateView from '@/views/MessageTemplates/UpdateMessageTemplateView/UpdateMessageTemplateView.vue'

export default [
  {
    path: '/merchants/:merchantID?/message-templates',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_template_update_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: MessageTemplateListView,
    name: 'MessageTemplateList'
  },
  {
    path: '/merchants/:merchantID/message-templates/:messageTemplateID?',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_template_update_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: UpdateMessageTemplateView,
    name: '',
    children: [
      {
        path: '',
        meta: {
          require_custom_roles: [
            'super_admin',
            'admin',
            'is_template_update_allowed'
          ],
          require_roles: ['super_admin', 'admin', 'staff']
        },
        component: MessageTemplateEdit,
        name: 'MessageTemplateEdit'
      },
      {
        path: '/merchants/:merchantID/message-templates/:messageTemplateID?/text',
        meta: {
          require_custom_roles: [
            'super_admin',
            'admin',
            'is_template_update_allowed'
          ],
          require_roles: ['super_admin', 'admin', 'staff']
        },
        component: MessageTemplateText,
        name: 'MessageTemplateText'
      },
      {
        path: '/merchants/:merchantID/message-templates/:messageTemplateID?/email',
        meta: {
          require_custom_roles: [
            'super_admin',
            'admin',
            'is_template_update_allowed'
          ],
          require_roles: ['super_admin', 'admin', 'staff']
        },
        component: MessageTemplateEmail,
        name: 'MessageTemplateEmail'
      }
    ]
  }
]
