const CustomerListView = () =>
  import('@/views/Customers/CustomerListView/CustomerListView.vue')
const CustomerDetailView = () =>
  import('@/views/Customers/CustomerDetailView/CustomerDetailV2.vue')
const CustomerCreateView = () =>
  import('@/views/Customers/CustomerCreateView/CustomerCreateView.vue')
const CustomerImportView = () =>
  import('@/views/Customers/CustomerImportView/CustomerImportView.vue')
const AccountDetails = () =>
  import('@/views/Customers/CustomerDetailView/AccountDetailsV2.vue')
const PaymentHistory = () =>
  import('@/views/Customers/CustomerDetailView/PaymentHistory.vue')
const Notes = () => import('@/views/Customers/CustomerDetailView/Notes.vue')
const AccountCustomers = () =>
  import('@/views/Customers/CustomerDetailView/AccountCustomers.vue')
const CustomerPaymentMethods = () =>
  import('@/views/Customers/CustomerDetailView/CustomerPaymentMethods.vue')

export default [
  {
    path: '/merchants/:merchantID/customers/import',
    meta: {
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_customer_update_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: CustomerImportView,
    name: 'CustomerImport'
  },
  {
    path: '/merchants/:merchantID/customers/create',
    meta: {
      disallowIntegrated: true,
      require_custom_roles: [
        'super_admin',
        'admin',
        'is_customer_update_allowed'
      ],
      require_roles: ['super_admin', 'admin', 'staff']
    },
    component: CustomerCreateView,
    name: 'CustomerCreate'
  },
  {
    path: '/merchants/:merchantID?/customers/',
    component: CustomerListView,
    name: 'CustomerList',
    children: [
      {
        path: ':customerID',
        component: CustomerDetailView,
        meta: { isDetailView: true, navClass: 'light-nav' },
        children: [
          {
            path: '',
            component: AccountDetails,
            name: 'CustomerAccountDetails',
            meta: { isDetailView: true, navClass: 'light-nav' }
          },
          {
            path: 'history',
            component: PaymentHistory,
            name: 'CustomerAccountHistory',
            meta: { isDetailView: true, navClass: 'light-nav' }
          },
          {
            path: 'notes',
            component: Notes,
            name: 'CustomerAccountNotes',
            meta: { isDetailView: true, navClass: 'light-nav' }
          },
          {
            path: 'co-borrowers',
            component: AccountCustomers,
            name: 'AccountCustomers',
            meta: { isDetailView: true, navClass: 'light-nav' }
          },
          {
            path: 'payment-methods',
            component: CustomerPaymentMethods,
            name: 'CustomerPaymentMethods',
            meta: { isDetailView: true, navClass: 'light-nav' }
          }
        ]
      }
    ]
  },
  {
    path: '/merchants/:merchantID/customers/:customerID/detail',
    component: CustomerDetailView,
    name: 'CustomerDetailFullView'
  }
]
