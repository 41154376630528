<template>
  <ValidationProvider :name="name" :rules="rules" mode="eager">
    <b-form-group
      class="h-form-group"
      :label="$attrs.label"
      :label-for="kebabName"
    >
      <b-input-group :prepend="`${prepend}`" :append="`${append}`">
        <b-form-select
          :id="kebabName"
          class="select-input-field"
          v-bind="$attrs"
          :name="name"
          :disabled="!editing"
          :data-cy="makeSelector()"
          @input="e => $emit('input', e)"
          @change="e => $emit('change', e)"
        />
      </b-input-group>
      <div class="text-danger field-errors">
        <span v-for="err in $attrs.errors[name]" :key="err">
          {{ err }}
        </span>
      </div>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { kebabCase } from 'lodash-es'
export default {
  props: {
    append: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    prepend: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    }
  },
  computed: {
    kebabName() {
      return kebabCase(this.name)
    }
  },
  methods: {
    makeSelector() {
      const attrs = this.$attrs
      const attr =
        attrs['data-cy'] ?? attrs?.cy ?? attrs?.label ?? attrs?.id ?? ''
      return kebabCase(attr)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-input-field {
  width: 228px;
  height: 31px;
  padding: 6px 15px;

  color: $darker-gray;
  font-size: 1em;
  font-family: $body-font-family;
  line-height: 16px;

  border: 1px solid $input-border;

  &:focus {
    border: 1px solid $input-border;
    outline: none;
    box-shadow: none;
  }

  &.custom-select:disabled {
    color: $darker-gray;

    background-color: transparent;
    border-style: none;
    opacity: 1;
  }
}
</style>
