const MerchantLocationListView = () =>
  import(
    '@/views/MerchantLocations/MerchantLocationListView/MerchantLocationListView.vue'
  )
const MerchantLocationDetailView = () =>
  import(
    '@/views/MerchantLocations/MerchantLocationDetailView/MerchantLocationDetailView.vue'
  )
const MerchantLocationCreateView = () =>
  import(
    '@/views/MerchantLocations/MerchantLocationCreateView/MerchantLocationCreateView.vue'
  )

export default [
  {
    path: '/merchants/:merchantID?/locations',
    component: MerchantLocationListView,
    name: 'MerchantLocationList'
  },
  {
    path: '/merchants/:merchantID/locations/create',
    component: MerchantLocationCreateView,
    name: 'MerchantLocationCreate'
  },
  {
    path: '/merchants/:merchantID/locations/:locationID',
    component: MerchantLocationDetailView,
    name: 'MerchantLocationDetail'
  }
]
