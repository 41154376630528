


























import { Component, Watch, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions(['logout'])
  }
})
export default class InactiveNotice extends Vue {
  timeRemaining = '60'
  ticker = null as any
  logout!: any

  @Watch('timeRemaining')
  forceLogout(value: number) {
    if (value === 0)
      this.$nextTick(() => {
        this.logout()
      })
  }

  created() {
    this.$eventBus.on('clearInterval', this.clearInterval)
  }

  beforeDestroy() {
    this.clearInterval()
    this.$eventBus.off('clearInterval', this.clearInterval)
  }

  onShow() {
    // every second calc the time remaining
    this.ticker = setInterval(this.calcTimeRemaining, 100)
  }

  calcTimeRemaining() {
    const now = moment().utc().unix()
    const expiresAt =
      this.$store.state.authStore.lastActivity +
      process.env.VUE_APP_SESSION_TIMEOUT * 60
    const remaining = expiresAt - now
    if (-1 < remaining) {
      this.timeRemaining = remaining.toString()
    }
  }

  close() {
    this.$bvModal.hide('inactive-modal')
  }

  onHide() {
    this.clearInterval()
    this.timeRemaining = '60'
    this.$store.dispatch('continueSession')
  }

  clearInterval() {
    clearInterval(this.ticker)
  }
}
