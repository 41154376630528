import jwt_decode from 'jwt-decode'
import moment from 'moment'

export default {
  accessToken(state) {
    return state.accessToken
  },
  accessTokenTimeToLive(_, getters) {
    // return function as not to cache
    return () => {
      // Get how long until access token expires
      let ttl = 0
      const claims = getters.accessClaims

      if (claims && claims.exp) {
        ttl = claims.exp - moment().utc().unix()
      }

      return ttl
    }
  },
  refreshTokenTimeToLive(_, getters) {
    // return function as not to cache
    return () => {
      // Get how long until refresh token expires
      let ttl = 0
      const claims = getters.refreshClaims

      if (claims && claims.exp) {
        ttl = claims.exp - moment().utc().unix()
      }

      return ttl
    }
  },
  isAuthenticated(_, getters) {
    // If the access token is still
    return 0 < getters.accessTokenTimeToLive()
  },
  isInactive(state, getters) {
    return getters.isAuthenticated && state.isInactive
  },
  accessClaims(state) {
    if (typeof state.accessToken === 'string') {
      return jwt_decode(state.accessToken)
    }
    return {}
  },
  refreshClaims(state) {
    if (typeof state.refreshToken === 'string') {
      return jwt_decode(state.refreshToken)
    }
    return {}
  },
  profile(state) {
    return state.profile
  },
  isLegacyUser(state) {
    return state.profile && state.profile.organization.in_legacy_app
  },
  isSuper(_, getters) {
    const claims = getters.accessClaims
    return claims.groups?.includes('super')
  },
  profileMerchantLogo(state) {
    let logo = state.profile?.organization.logo
    if (!logo) {
      logo = 'https://www.blytzpay.com/hubfs/Logos/Blytzpay-Logo-Pink.png'
    }
    return logo
  },
  heartbeat(state) {
    return state.heartbeat
  }
}
