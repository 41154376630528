














































import endpoints from '@/utils/endpoints'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

interface MessageTemplate {
  sms_body: string
}

@Component
export default class MessageTemplateEmail extends Vue {
  @Prop({ type: Object }) readonly messageTemplate!: MessageTemplate
  renderedBody = 'Please save this template to view preview'

  mounted() {
    if (this.$route.params.messageTemplateID) {
      this.renderTemplate()
    }
  }

  async renderTemplate() {
    const response = await this.axios.post(
      endpoints.randomRenderMessageTemplate(
        this.$route.params.merchantID,
        this.$route.params.messageTemplateID
      ),
      { message_template_body: this.smsBody, type: 'EMAIL' }
    )
    this.renderedBody = response.data.rendered_body
  }

  get orgAbbreviation() {
    return this.$store.getters.profile.organization.name.charAt(0)
  }

  get localTime() {
    return moment().format('LT')
  }

  get customerName() {
    if (this.$store.getters.profile?.first_name) {
      return this.$store.getters.profile.first_name
    } else {
      return 'Customer'
    }
  }

  get smsBody() {
    return this.messageTemplate.sms_body
      ? this.messageTemplate.sms_body
      : 'New Template content goes here!'
  }
}
