export function bypassRefreshResponse(data) {
  const obj = JSON.parse(data)
  obj['bypassRefresh'] = true
  return obj
}

export default {
  methods: {
    bypassRefreshResponse
  }
}
